import { ManagementKind } from "../../types/fulfillment/inventory";

import { INVENTORY_MANAGEMENT_KIND_MAP } from "../../constants/fulfillment/inventory";
import { toFormattedDate } from "../common/date";
import { omitWithEllipsis } from "../common/string";
import { getFormattedSingleSkuId } from "./fulfillment";

/**
 * inventoryPayload 배열을 요소의 locationId 오름차순으로 정렬
 */
function reOrderInventoryAdjustmentPayloadByLocationId<T>(
  inventoryPayload: ({ locationId: number } & T)[]
) {
  if (!inventoryPayload?.length) return [];

  return [...inventoryPayload].sort((a, b) => {
    return a.locationId - b.locationId;
  });
}

function getCombinedSkuValueForPDAInventory({
  skuId,
  skuBarcode,
}: {
  skuId: number | undefined;
  skuBarcode: string | undefined;
}) {
  return `${getFormattedSingleSkuId(skuId)}${
    skuBarcode
      ? ` / ${omitWithEllipsis({
          src: skuBarcode,
          maxLength: 6,
          ellipsis: "...",
        })}`
      : ""
  }`;
}

const getCombinedSkuManagementValueForPDAInventory = ({
  managementKind,
  managementDate,
}: {
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
}) => {
  return `${
    managementKind ? INVENTORY_MANAGEMENT_KIND_MAP[managementKind] : "-"
  } ${toFormattedDate(managementDate, "YYYY-MM-DD")}`;
};

export {
  reOrderInventoryAdjustmentPayloadByLocationId,
  getCombinedSkuValueForPDAInventory,
  getCombinedSkuManagementValueForPDAInventory,
};
