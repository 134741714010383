import { BofulAuthority } from "@sellernote/_shared/src/types/fulfillment/auth";

export function getActorKindByUserAuthority(authority: BofulAuthority) {
  switch (authority) {
    case "admin": {
      return "warehouseAdmin";
    }
    case "manager": {
      return "warehouseManager";
    }
    case "whMaster": {
      return "warehouseAdmin";
    }
    case "partTimer": {
      return "warehouseWorker";
    }
  }
}

/** base64string을 파일 객체로 만들기 */
export function getFileFromBase64(imageURL: string, fileName: string) {
  const arr = imageURL.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
}
