import { InspectProblem } from "../../types/fulfillment/inspection";

/**
 * 비정상 상품 조건 : inspectProblems 에 비정상 상품에 대한 정보가 존재하는 경우. (불일치상품 제외)
 */
const checkForProblemItem = (item: { inspectProblems: InspectProblem[] }) => {
  return (
    !!item.inspectProblems.length &&
    !!item.inspectProblems.every((problem) => problem.problem !== "불일치상품")
  );
};

/**
 * 불일치 상품 조건 : inspectProblems의 객체에 problem:"불일치상품" 이 포함되어있는 경우.
 */
const checkForUnverifiedItem = (
  item:
    | {
        inspectProblems?: InspectProblem[];
      }
    | undefined
) => {
  if (!item || !item.inspectProblems) {
    return false;
  }

  return item.inspectProblems.some(
    (problem) => problem.problem === "불일치상품"
  );
};

/**
 * 검수 작업 중에는 아직 고객이 상품을 매칭하기 전이므로 SKU ID 유무로 불일치 상품을 검사할 수 있음
 */
const checkForUnverifiedItemAsInspection = (item: {
  skuId: number | undefined;
}) => !item.skuId;

/**
 * 검수 작업 중 불일치 상품이 아닌 일반 아이템 여부를 확인
 */
const checkForNormalItemAsInspection = (item: { skuId: number | undefined }) =>
  !!item.skuId;

/**
 * 정상 상품 조건 : inspectProblems의 객체에 data가 없는 경우.
 */
const checkForNormalItem = (item: { inspectProblems: InspectProblem[] }) => {
  return !item.inspectProblems.length;
};

export {
  checkForProblemItem,
  checkForUnverifiedItem,
  checkForUnverifiedItemAsInspection,
  checkForNormalItemAsInspection,
  checkForNormalItem,
};
